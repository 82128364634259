import React, { Component } from 'react';
import PackagingC from "../../../images/screenshots/cancompatibility.jpg";
import Packlims from "../../../images/packlims.png";
import Packlimslogo from "../../../images/packlimslogo.svg";
import Vidsource from "../../../assets/vid/packagingnew.mp4";
import Vidsourcepackaging from "../../../assets/vid/packaging.gif";

const styles = {
    vidpackgif: {
		backgroundImage: `url(${Vidsourcepackaging})`
	},
};


class Packaginginternalbanner extends Component {


    constructor(props) {
        super(props);
        this.state = {
            vidsrcc: ''
        };
      }

      swapVidSource = () => {
        this.setState({
            vidsrcc: Vidsource
          });
}

playVid = () => {
    if(this.refs.vidRefc){
    this.refs.vidRefc.play();
    }
}

pauseVideo = () => {
    if(this.refs.vidRefc){
        this.refs.vidRefc.pause();
        }
}

componentDidMount = () => {
    this.swapVidSource();
    setTimeout(() => {
        this.playVid();
      }, 1000);
  };

  componentWillUnmount = () => {
      this.pauseVideo();
  };

    render() {

        return (
            <div>
                <section className="internalbanner">
	<div className="overfunk"></div>
    <video className="showondesktop" ref="vidRefc" autoplay muted loop id="internalbannervideo" key={this.state.vidsrcc}>
<source src={this.state.vidsrcc} type="video/mp4"/>
</video>
<div className="showonmobile gifbanger" style={styles.vidpackgif}></div>
<div className="container">
			<div className="columns is-marginless">
				<div className="column is-half">
					<p className="topsubtitle">Client &amp; Supplier Certification Control</p>
					<h1 className="bigish">Packaging Process</h1>
					<p className="undersubtitle">Requesting, progressing and maintaining supplier certification for packaging designs</p>
				</div>
			</div>
		</div>
	</section>
	<section className="under-internalbanner graddown">
            <div className="threeboxes">
			<div className="container">
				<div className="boxcard norise">
                    
				<div className="columns is-marginless">
					<div className="column is-10">
							<h2>PackLIMS | The Packaging-focused Lims System With Real-world, Real-time Data Capture</h2><hr/>
							<p>Packlims is a laboratory solution that re-imagines data capture and dramatically improves testing efficiency.</p><br/>
					</div>
				</div>
	  <div className="columns is-marginless">
									<div className="column is-6 pictext">
									<h3>Specifically designed for Packaging Testing Laboratories without a LIMS system.</h3>
			<p className="bigpee">PackLIMS will help you retrieve data quickly, even during regulatory audits, reduces human error and eliminates any possibility of data loss or false reporting.</p>
            <p className="bigpee">It prevents the use of outdated or uncalibrated equipment for analytical testing, assuring accuracy and the quality of results.</p>
            <p className="bigpee">It helps standardise testing workflows, while providing complete and accurate control of the testing process.</p>
            <p className="bigpee">It allows you to manage the tests conducted on samples, enables automatic or easy entry of results, tracking approval/validation of results and generation of reports.</p>
<a href="http://packlims.com/" className="button buttonNudger">Go to PackLIMS website</a>
									</div>
									<div className="column is-6">
										<figure className="fullimage">
											<img src={Packlims} alt="PackLims screenshot" />
										</figure>
									</div>
								</div>
</div>
			</div>
            </div>

<div className="underitems">
<div className="overfunk"></div>
<div className="container">
  <div className="columns is-marginless">
  <div className="column is-6">
  <img src={Packlimslogo} className="packlimsLogo"/>
  <p>Track progress of the <span>certification process</span>, and collaborate with suppliers</p>
  </div>
    </div>
</div>
</div>
		</section>
            </div>
        );
    }
}

export default Packaginginternalbanner;